<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.id"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 350"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-biohazard
        </v-icon>
        {{ data?.id ? 'Edit' : 'Create' }} Indication
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      grow
      dark
    >
      <v-tab
        v-for="tabIcon in [
          { icon: 'fal fa-circle-info', disabled: false },
          { icon: 'fal fa-language', disabled: !data.id }
        ]"
        :key="tabIcon.icon"
        :disabled="tabIcon.disabled"
        class="px-0"
      >
        <v-icon>{{ tabIcon.icon }}</v-icon>
      </v-tab>
    </v-tabs>
    <v-form
      ref="indicationForm"
      v-model="valid"
      :readonly="!$auth.check({ indications: 'edit' })"
      @submit.prevent="updateIndication"
    >
      <v-tabs-items v-model="tab">
        <v-container class="spy-0">
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="data.indication.en"
                  label="Indication"
                  dense
                  class="required"
                  :rules="[v => !!v || 'Indication is required']"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="data.internal_name"
                  label="Internal Name"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <source-select
                  v-model="data.sources"
                  label="Sources"
                  dense
                  menu-props="left"
                  class="required"
                  :rules="[v => !!v.length || 'At least one source is required']"
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="data.default"
                  :label="`Default indication for ${ data.sources.length > 1 ? 'these sources' : 'this source' }`"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="data.gender"
                  label="Only available for this gender"
                  dense
                  menu-props="left"
                  clearable
                  :items="[
                    { text: $t('male'), value: 'male' },
                    { text: $t('female'), value: 'female' },
                    { text: $t('unknown'), value: 'unknown' }
                  ]"
                />
              </v-col>

              <v-col cols="12">
                <organism-category-picker
                  :value.sync="data.organism_categories"
                  label="Organism Categories"
                  :add-category="false"
                  :prepend-icon="false"
                  multiple
                  dense
                  menu-props="left"
                />
              </v-col>

              <v-col cols="12">
                <diagnosis-group-picker
                  :value.sync="data.diagnosis_groups"
                  label="Diagnosis Groups"
                  clearable
                  multiple
                  dense
                  menu-props="left"
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="data.special_dosing"
                  label="Special dosing"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="data.hide_from_qa"
                  label="Hide from QA choices"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="data.hide_when_pregnant"
                  label="Hide when patient is pregnant"
                  :disabled="data.only_when_pregnant"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="data.only_when_pregnant"
                  label="Only show if patient is pregnant"
                  :disabled="data.hide_when_pregnant"
                  dense
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <v-text-field
              v-for="language in $languages.filter(x => x.value != 'en')"
              :key="language.value"
              v-model="data.indication[language.value]"
              :label="language.text"
              :lang="language.value"
            >
              <template #prepend>
                <country-flag
                  :country="language.flag || language.value"
                  class="mt-n2"
                />
              </template>
              <template
                v-if="language.value !== 'en' && data.indication.en"
                #append
              >
                <v-btn
                  icon
                  @click="translateText(data.indication.en, language.value, v => $set(data.indication, language.value, v))"
                >
                  <v-icon small>
                    fa fa-language
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-form>
    <template
      v-if="$auth.check({ indications: 'edit' })"
      #append
    >
      <v-divider />
      <v-col class="pb-0">
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateIndication"
        >
          {{ data?.id ? 'Update' : 'Create' }} Indication
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          v-if="data.id && $auth.check({ indications: 'edit' })"
          color="error"
          block
          outlined
          @click="deleteIndication"
        >
          <v-icon
            small
            left
          >
            fal fa-trash
          </v-icon>
          Delete Indication
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      OrganismCategoryPicker: () => import('@/components/organism/OrganismCategoryPicker.vue'),
      DiagnosisGroupPicker: () => import('@/components/DiagnosisGroupPicker.vue'),
      SourceSelect: () => import('@/components/source/SourceSelect.vue'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({
          indication: {
            en: '',
          },
        }),
      },
    },
    data: () => ({
      tab: 'info',
      valid: false,
      loading: false,
    }),
    watch: {
      data: {
        deep: true,
        handler (data) {
          if (!data.indication) {
            this.$set(data, 'indication', { en: '' })
          }
        },
      },
    },
    methods: {
      updateIndication () {
        if (this.valid) {
          this.loading = true
          this.axios.request({
            method: this.data.id ? 'patch' : 'post',
            url: this.data.id ? 'admin/indications/' + this.data.id : 'admin/indications',
            data: this.data,
          })
            .then(() => {
              this.$root.$emit('fetch-indications')
              this.$emit('close-drawer')
              this.$toast.success('Indication updated')
            })
            .catch(error => {
              this.$toast.error(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      deleteIndication () {
        this.$dialog.warning({
          text: 'Do you really want to delete this indication?',
          title: 'Delete Indication',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete('admin/indications/' + this.data.id)
                .then(() => {
                  const index = this.indications.findIndex(x => x.id === this.data.id)
                  this.$delete(this.indications, index)
                  this.$toast.success('Indication deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
